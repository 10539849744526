import React from 'react'
import Image from 'next/image';

import TechnologiesImage from '../../assets/images/TechnologiesImg/technologies.png'

export const Technology = () => {
  return (
    <div className='max-w-[1920px] mx-auto md:mt-10'>
        <h1 className='md:text-header text-header-mbl font-sourcesans relative text-center'>
            <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-1 md:after:h-2 after:top-[100px] sm:after:top-[50px] md:after:top-[65px] after:left-[40%] md:after:left-[45%] after:border-4 md:after:border-4 after:border-secondary-500 after:rounded">
                <span className="text-primary-500 mr-2">Technologies we are</span>
                <span className="text-orange mr-3">expert</span>
                <span className="text-primary-500 mr-2">in</span>
            </span>
        </h1>

        <div className='technologies-image w-full mt-4 md:h-[450px] h-[350px] bg-cover md:bg-contain'/>

    </div>
  )
}
